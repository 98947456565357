import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'

import * as S from '../../styles/workouts.styles'
import { WORKOUTS_SEE_ALL_ROUTE } from '../../constants/routes'
import Layout from '../../templates/LiveLayout'
import WorkoutsRow from '../../components/WorkoutsRow'
import * as session from '../../services/session'
import { BACKGROUND } from '../../constants/colors'
import { RootState } from '../../redux/reducers/rootReducers'
import { onFetchWorkouts as onFetchWorkoutsAction } from '../../redux/actions'
import { workoutsAllSelector } from '../../redux/selectors'
import { GenericWorkout } from '../../types/generic-workout'
import { withErrorHandler } from '../../components/errorHandler'
import { PageRendererProps } from 'gatsby'
import { isMobile, isTablet } from 'react-device-detect'

const Workouts: React.FC<Props> = (props: Props) => {
  session.redirectIfNotLoggedIn()
  const { workouts, onFetchWorkouts } = props
  useEffect(() => {
    onFetchWorkouts()
  }, [onFetchWorkouts])

  const handleSeeAll = (type: string, genericWorkouts: GenericWorkout[]) => {
    navigate(WORKOUTS_SEE_ALL_ROUTE, {
      state: { type, workouts: genericWorkouts },
    })
  }

  const isMobileOrTablet = isTablet || isMobile

  return (
    <Layout title="Manage Workouts" location={props.location}>
      <div style={{ paddingTop: '1rem', backgroundColor: BACKGROUND }} />
      <div style={{ width: isMobileOrTablet ? '100%' : 'calc(100% - 250px)' }}>
        {workouts.map(
          workout =>
            workout.workouts.length > 0 && (
              <S.WorkoutRow>
                <S.SectionHeader>
                  <S.Title>{workout.type}</S.Title>
                  <S.SeeAll
                    onClick={() => handleSeeAll(workout.type, workout.workouts)}
                  >
                    See all
                  </S.SeeAll>
                </S.SectionHeader>
                <WorkoutsRow workouts={workout.workouts} isEditable={true} />
              </S.WorkoutRow>
            ),
        )}
      </div>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  workouts: workoutsAllSelector(state),
})

const mapDispatchToProps = {
  onFetchWorkouts: onFetchWorkoutsAction.request,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PageRendererProps

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(Workouts))
